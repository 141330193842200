import "./home-page-style.css";
import { useState } from "react";
import { Button, Modal, Input } from "antd";
import React from "react";
import { createWebhook } from "../../common/services";
import axios, { AxiosResponse } from "axios";
import { WebhookResponse } from "../../common/types";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [webhookId, setWebhookId] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleWebhookLoadInputChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setWebhookId(e.target.value);

  axios
    .get("https://geolocation-db.com/json/")
    .then((response) => {
      setIpAddress(response.data.IPv4);
    })
    .catch((err) => console.log(err));

  function create() {
    setIsModalOpen(false);

    createWebhook("", ipAddress)
      .then(function (response: AxiosResponse<WebhookResponse, any>) {
        console.log(response);
        if (response.data.id) {
          window.location.replace(`/${response.data.id}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const loadExistingWebhook = () => {
    window.location.replace(`/${webhookId}`);
  };

  return (
    <div className="col-md-12 home">
      <h1 className="d-block mb-5">
        hookz.dev is a free online tool for developers to test and debug
        webhooks and other types of HTTP requests.
      </h1>
      <span className="d-block mb-5 caption">
        To get started, click the button below to generate a Webhook URL.
      </span>
      <br />
      <br />
      <button onClick={create} className="btn-custom">
        <span>Create A Webhook</span>
      </button>
      <button
        onClick={showModal}
        className="btn-custom btn-custom-load-webhook"
      >
        <span>Load Existing Webhook</span>
      </button>

      <>
        <Modal
          title="Webhook ID"
          open={isModalOpen}
          onOk={loadExistingWebhook}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={loadExistingWebhook}>
              Load
            </Button>,
          ]}
        >
          <p>
            <Input
              value={webhookId}
              placeholder="name"
              onChange={handleWebhookLoadInputChange}
            />
          </p>
        </Modal>
      </>
    </div>
  );
}
export default Home;
