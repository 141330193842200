import axios from "axios";
import { ENV_URL } from "./constants";

export const createWebhook = (webhookName: string, ipAddress: string) =>
  axios.post(ENV_URL + "/webhookz", {
    name: webhookName,
    ipAddress: ipAddress,
    headers: {
      "Access-Control-Allow-Origin": "*",
      crossDomain: true,
    },
  });
