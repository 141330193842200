import React from "react";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { ENV_URL } from "../../common/constants";
import { useParams } from "react-router-dom";
import { WebhookResponse } from "../../common/types";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { Button, Typography } from "antd";
import WebhookData from "../webhook-data";
import "./webhook-style.css";

function Webhook() {
  const { webhookId } = useParams();
  const { Paragraph, Text } = Typography;

  const webhookUrl = `${ENV_URL}/d/${webhookId}`;

  const [hooksData, setHooksData] = useState<any[]>([]);
  const [selectedWebhookData, setSelectedWebhookData] = useState<any>({});
  const [selectedWebhookIndex, setSelectedWebhookIndex] = useState(1);
  const [isValidWebhook, setIsValidWebhook] = useState(true);

  TimeAgo.addDefaultLocale(en);

  useEffect(() => {
    axios
      .get(`${ENV_URL}/webhookz/${webhookId}/data`)
      .then((response: any) => {
        setHooksData(response.data);

        const selectedWebhookData = response.data.find(
          (data: any) => data.id === response.data[0].id
        );

        setSelectedWebhookData(selectedWebhookData);
        setIsValidWebhook(true);
      })
      .catch((error) => {
        console.log("error getting all data ", error);
        setIsValidWebhook(false);
      });
  }, []);

  const select = (id: number, index: number) => {
    const selectedWebhookData = hooksData.find((data) => data.id === id);
    setSelectedWebhookData(selectedWebhookData);
    setSelectedWebhookIndex(index);
  };

  const isEmpty = (obj: any) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  return (
    <>
      {" "}
      {hooksData.length > 0 && (
        <>
          <div className="webhooks-nav">
            <p className="request-total">
              <span>{hooksData.length}</span> Request(s)
            </p>
            <ul>
              {hooksData.length > 0 &&
                hooksData.map((data, index) => (
                  <WebhookItemListView
                    key={data.id}
                    select={select}
                    webhook={data}
                    index={index + 1}
                    active={index + 1 === selectedWebhookIndex}
                  />
                ))}
            </ul>
          </div>
        </>
      )}
      {isValidWebhook && (
        <article className="webhooks-content">
          <p>
            Here's your unique webhook URL. You can now make any kind of
            request.
            <span>
              <b>
                <Paragraph copyable={{ text: webhookUrl }}>
                  <Text strong code className="request-url-copy">
                    {webhookUrl}
                  </Text>
                </Paragraph>
              </b>
            </span>
          </p>
          {isEmpty(selectedWebhookData) ? (
            `No request data selected.`
          ) : (
            <WebhookData
              details={selectedWebhookData}
              selectedWebhookIndex={selectedWebhookIndex}
            />
          )}
        </article>
      )}
    </>
  );
}

const WebhookItemListView = ({ webhook, select, index, active }: any) => {
  const data = JSON.parse(webhook.data);

  return (
    <li
      key={webhook.id}
      style={{ cursor: "pointer" }}
      onClick={() => select(webhook.id, index)}
      className={active ? "selected" : ""}
    >
      <span className="counter">#{index}</span>{" "}
      <span
        className={`webhook-item-method webhook-item-method-${data.httpMethod.toLowerCase()}`}
      >
        {data.httpMethod}
      </span>{" "}
      <span className="webhook-item-date">
        <ReactTimeAgo date={webhook.createdAt} />
      </span>
      <br />
      <span className="webhook-item-http-version">
        HTTP Version: {data.httpVersion}
      </span>
    </li>
  );
};

export default Webhook;
