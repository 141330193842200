import React from "react";
import "./about-page-style.css";

function About() {
  return (
    <div className="about-content">
      <p>
        hookz.dev is a free and open source web tool to enable developers
        quickly create webhooks to debug and inspect http requests.
      </p>
      <p>
        The open source (unmaintained) version of the application is built with{" "}
        <a href="https://expressjs.com/">nodejs/expressjs</a> (backend) and{" "}
        <a href="https://reactjs.org/">reactjs</a> (frontend). View on{" "}
        <a href="https://github.com/rasheeda">github</a>.
      </p>
    </div>
  );
}

export default About;
