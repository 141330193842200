import React from "react";
import "./webhook-data-style.css";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { Typography } from "antd";

function WebhookData(details: any, selectedWebhookIndex: any) {
  const data = JSON.parse(details.details.data);

  return (
    <div>
      {details.details.id && (
        <div className="webhook-data-content">
          <p className="selected-index">
            #{selectedWebhookIndex.selectedWebhookIndex}
          </p>
          <table>
            <tbody>
              <tr>
                <td>{data.httpMethod}</td>
                <td>{data.url}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{details.details.createdAt}</td>
              </tr>
              <tr>
                <td>Host</td>
                <td>{data.ip}</td>
              </tr>
            </tbody>
          </table>
          <h3 className="headers">Headers</h3>
          <JSONPretty id="json-pretty" data={data.headers}></JSONPretty>

          <h3 className="query">Query</h3>
          <JSONPretty id="json-pretty" data={data.query}></JSONPretty>

          <h3 className="request-body">Request Body</h3>
          <JSONPretty id="json-pretty" data={data.body}></JSONPretty>
        </div>
      )}
    </div>
  );
}

export default WebhookData;
