import React from "react";
import "./layout-page-style.css";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { createWebhook } from "../../common/services";
import axios, { AxiosResponse } from "axios";
import { WebhookResponse } from "../../common/types";
import { Button, Modal, Input } from "antd";
import { Footer } from "antd/es/layout/layout";

function MainLayout() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [webhookName, setWebhookName] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleWebhookNameInputChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setWebhookName(e.target.value);

  axios
    .get("https://geolocation-db.com/json/")
    .then((response) => {
      setIpAddress(response.data.IPv4);
    })
    .catch((err) => console.log(err));

  function create() {
    setIsModalOpen(false);

    createWebhook(webhookName, ipAddress)
      .then(function (response: AxiosResponse<WebhookResponse, any>) {
        console.log(response);
        if (response.data.id) {
          window.location.replace(`/${response.data.id}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className="site-wrap">
        <div className="site-mobile-menu">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
        </div>

        <header className="site-navbar" role="banner">
          <div className="container-fluid">
            <div className="row">
              <nav className="site-navigation" role="navigation">
                <ul className="site-menu">
                  <li>
                    <h1>
                      <a href="/" className="hookz-text">
                        hookz
                        <span className="dev-text">.dev</span>{" "}
                      </a>
                    </h1>
                  </li>
                  <li className="active">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy</a>
                  </li>
                  <li className="cta">
                    <a onClick={create}>New Hook</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <div className="site-section site-hero">
          <div className="container">
            <Outlet />
          </div>
        </div>
        <Footer className="site-footer">
          made with ∞ tea |{" "}
          {/* <a href="https://mandeeya.io">mandeeya.io </a> |{" "} */}
          {new Date().getFullYear()} |{" "}
          <a href="/privacy" className="active">
            Privacy Terms
          </a>
        </Footer>
      </div>

      <>
        <Modal
          title="Provide a name for your webhook"
          open={isModalOpen}
          onOk={create}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={create}>
              Create
            </Button>,
          ]}
        >
          <p>
            <Input
              value={webhookName}
              placeholder="name"
              onChange={handleWebhookNameInputChange}
            />
          </p>
        </Modal>
      </>
    </>
  );
}

export default MainLayout;
